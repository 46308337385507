import { DOMAIN_URL, APP_NAME, DEFAULT_AUTHOR } from '@/constants/';
import { DEFAULT_IMAGE } from '@/constants/seo';

/**
 * Generates meta and link tags for a web page, including blog posts.
 * @param {Object} params - The parameters for generating meta tags.
 * @param {string} params.fullTitle - The title of the page - does not get concateneted with "- Users Driven".
 * @param {string} params.title - The title of the page.
 * @param {string} params.description - The description of the page.
 * @param {string} params.pagePath - The path of the page.
 * @param {string} [params.imagePath=DEFAULT_IMAGE] - The file path for an image.
 * @param {string} [params.contentType='website'] - The type of content (e.g., 'website', 'article').
 * @param {string} [params.author=DEFAULT_AUTHOR] - The author of the content.
 * @param {string} [params.date] - The publication date of the page (typically for a blog post).
 * @param {string} [params.modifiedDate] - The modified date of the page (typically for a blog post).
 * @param {string[]} [params.sections] - The categories or sections of a post.
 * @param {string[]} [params.tags] - The tags associated with a post.
 * @returns {Object} An object containing meta and link tags.
 */
export default function generateHTMLTagsForSEO({
  fullTitle,
  title,
  description,
  pagePath,
  imagePath = DEFAULT_IMAGE,
  contentType = 'website',
  author = DEFAULT_AUTHOR,
  date,
  modifiedDate,
  sections = [],
  tags = [],
}) {
  const pageUrl = `${DOMAIN_URL}/${pagePath}`;
  const imageUrl = imagePath.startsWith('http')
    ? imagePath
    : `${DOMAIN_URL}/${imagePath}`;

  let specificTitle = fullTitle;

  if (contentType === 'website' && !fullTitle) {
    specificTitle = `${title} - ${APP_NAME}`;
  }

  const meta = [
    { name: 'description', content: description },
    { name: 'author', content: author },
    // Open Graph Meta Tags
    { property: 'og:title', content: specificTitle },
    { property: 'og:description', content: description },
    { property: 'og:type', content: contentType },
    { property: 'og:url', content: pageUrl },
    { property: 'og:image', content: imageUrl },
    { property: 'og:site_name', content: APP_NAME },
    // Twitter Card Meta Tags
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:title', content: specificTitle },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: imageUrl },
  ];

  if (contentType === 'article') {
    if (date) meta.push({ property: 'article:published_time', content: date });
    if (modifiedDate)
      meta.push({ property: 'article:modified_time', content: modifiedDate });
    sections.forEach((section) =>
      meta.push({ property: 'article:section', content: section }),
    );
    tags.forEach((tag) => meta.push({ property: 'article:tag', content: tag }));
  }

  const link = [{ rel: 'canonical', href: pageUrl }];

  // JSON-LD Structured Data
  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': contentType === 'article' ? 'Article' : 'WebPage',
    headline: specificTitle,
    description: description,
    image: imageUrl,
    url: pageUrl,
    datePublished: date,
    dateModified: modifiedDate || date,
    author: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Organization',
      name: APP_NAME,
      url: DOMAIN_URL,
      logo: {
        '@type': 'ImageObject',
        url: `${DOMAIN_URL}/images/logo-usersdriven-green.png`,
        width: 203,
        height: 34,
      },
      image: {
        '@type': 'ImageObject',
        url: imageUrl,
        width: 600,
        height: 600,
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': pageUrl,
      },
    },
  };

  return {
    title: specificTitle,
    meta,
    link,
    script: [{ type: 'application/ld+json', children: JSON.stringify(jsonLd) }],
  };
}
