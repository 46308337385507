import generateHTMLTagsForSEO from '@/utils/generateHTMLTagsForSEO';
import { DEFAULT_DESCRIPTION } from '@/constants/seo';

function generateDescription(fullTitle, title, customDescription) {
  if (customDescription) {
    return customDescription;
  }

  // If no custom description, generate one based on the title
  return `${fullTitle || title} | ${DEFAULT_DESCRIPTION}`;
}

export function removeLdJsonScript() {
  const existingScript = document.querySelector(
    'script[type="application/ld+json"]',
  );
  if (existingScript) {
    existingScript.remove(); // Remove existing JSON-LD script
  }
}

/**
 * Generates meta and link tags for a web page, including blog posts.
 * @param {Object} params - The parameters for generating meta tags.
 * @param {string} params.fullTitle - The title of the page - does not get concateneted with "- Users Driven".
 * @param {string} params.title - The title of the page.
 * @param {string} params.description - The description of the page.
 * @param {string} params.pagePath - The path of the page.
 * @param {string} [params.imagePath=DEFAULT_IMAGE] - The file path for an image.
 * @param {string} [params.contentType='website'] - The type of content (e.g., 'website', 'article').
 * @param {string} [params.author=DEFAULT_AUTHOR] - The author of the content.
 * @param {string} [params.date] - The publication date of the page (typically for a blog post).
 * @param {string} [params.modifiedDate] - The modified date of the page (typically for a blog post).
 * @param {string[]} [params.sections] - The categories or sections of a post.
 * @param {string[]} [params.tags] - The tags associated with a post.
 * @returns {Object} An object containing meta and link tags.
 */
export function useHTMLTagsForSEO({ fullTitle, title, description, ...rest }) {
  const finalDescription = generateDescription(fullTitle, title, description);

  const htmlTagsForSEO = generateHTMLTagsForSEO({
    fullTitle,
    title,
    description: finalDescription,
    ...rest,
  });

  useHead(htmlTagsForSEO);

  onUnmounted(() => {
    removeLdJsonScript();
  });

  return { htmlTagsForSEO };
}
