export const DOMAIN_URL = 'https://www.users-driven.com';
export const APP_NAME = 'Users Driven';
export const DEFAULT_AUTHOR = 'Yuval Carmeli';
export const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const NAVBAR_HEIGHT = 64;
